import { Controller } from "stimulus"

export class NestedFormController extends Controller {
  static targets = [ "template", "output" ]
  static classes = [ "record" ]
  static values = { indexWatermark: String }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replaceAll(this.indexWatermarkValue, new Date().getTime())
    this.outputTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(e) {
    e.preventDefault();

    const wrapper = e.currentTarget.closest(`.${this.recordClass}`);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      wrapper.querySelector("input[name*='_destroy']").value = 1;
    }
  }
}
