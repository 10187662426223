// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Turbo, cable } from "@hotwired/turbo-rails"

import "jquery"

// Bootstrap will automatically import Popper
import "bootstrap"

import "chartkick/chart.js"
import "chartkick/highcharts"

import "controllers"

import "./dropdown"
import "./loading_button_links"
import "./popover"
import "./tooltip"

Rails.start();
