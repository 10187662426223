import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sortField", "sortDirection"]

  sort(e) {
    e.preventDefault()

    const filterSubmitButton = this.sortFieldTarget.closest('form').querySelector('input[type="submit"]')
    this.sortFieldTarget.value = e.currentTarget.dataset.field
    this.sortDirectionTarget.value = e.currentTarget.dataset.direction
    filterSubmitButton.click()
  }
}
