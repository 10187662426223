
var tooltipBinder = function (event) {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
};

// Enable tooltips everywhere
document.addEventListener("turbo:load", tooltipBinder);
document.addEventListener("turbo:frame-load", tooltipBinder);
