import { Controller } from "stimulus"
import { Turbo, cable } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    earliestDate: String,
    startDate: String,
    endDate: String
  }

  connect() {
    const latest_date = moment()

    new Calendar({
      element: $(this.element),
      earliest_date: this.earliestDateValue,
      latest_date: latest_date,
      start_date: this.startDateValue,
      end_date: this.endDateValue,
      same_day_range: true,
      presets: [
        {
          label: 'Last 7 days',
          start: moment(latest_date).subtract(6, 'days'),
          end: latest_date
        },
        {
          label: 'Last 30 days',
          start: moment(latest_date).subtract(29, 'days'),
          end: latest_date
        },
        {
          label: 'Last month',
          start: moment(latest_date).subtract(1, 'month').startOf('month'),
          end: moment(latest_date).subtract(1, 'month').endOf('month')
        },
        {
          label: 'Last 3 months',
          start: moment(latest_date).subtract(3, 'month').startOf('month'),
          end: moment(latest_date).subtract(1, 'month').endOf('month')
        },
        {
          label: 'Last 6 months',
          start: moment(latest_date).subtract(6, 'month').startOf('month'),
          end: moment(latest_date).subtract(1, 'month').endOf('month')
        },
        {
          label: 'Last year',
          start: moment(latest_date).subtract(12, 'month').startOf('month'),
          end: moment(latest_date).subtract(1, 'month').endOf('month')
        },
        {
          label: 'All time',
          start: this.earliestDateValue,
          end: latest_date
        }
      ],
      callback: function() {
        const url = new URL(window.location.href)
        const searchParams = url.searchParams

        searchParams.set('start_date', moment(this.start_date).format('YYYY-MM-DD'))
        searchParams.set('end_date', moment(this.end_date).format('YYYY-MM-DD'))

        url.search = searchParams.toString()

        Turbo.visit(url)
      }
    });
  }
}
