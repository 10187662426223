import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "operatorWrapper", "valuesWrapper",
    "booleanOperatorSelect", "textOperatorSelect", "dateOperatorSelect", "integerOperatorSelect", "moneyOperatorSelect", "customerBelongToOperatorSelect", "customerHaveManyOperatorSelect", "associationProductOperatorSelect", "associationProductVariantOperatorSelect",
    "booleanSingleForm", "textSingleForm", "dateSingleForm", "dateRangeForm", "dateDurationForm", "integerSingleForm", "integerRangeForm", "moneySingleForm", "moneyRangeForm", "selectCustomerSingleForm", "selectProductSingleForm", "selectProductVariantSingleForm"
  ]

  renderOperators(e) {
    const fieldType = e.currentTarget.selectedOptions[0].dataset.fieldType
    const content = fieldType ? this[`${fieldType}OperatorSelectTarget`].innerHTML : ''
    this.operatorWrapperTarget.innerHTML = content
    this.valuesWrapperTarget.innerHTML = ''
  }

  renderValues(e) {
    const operatorType = e.currentTarget.selectedOptions[0].dataset.operatorType
    const content = operatorType ? this[`${operatorType}FormTarget`].innerHTML : ''
    this.valuesWrapperTarget.innerHTML = content
  }
}
