import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateValueInput" ]
  static values = { currentDate: String }

  connect() {
    const _this = this

    new Calendar({
      element: $(this.element),
      current_date: this.currentDateValue,
      format: { input: 'LL' },
      required: true,
      callback: function() {
        _this.dateValueInputTarget.value = moment(this.current_date).format('YYYY-MM-DD')
      }
    })
  }
}
