import { Controller } from "stimulus"
import "bootstrap"

export default class extends Controller {
  connect() {
    // setTimeout(function() {
    //    $('.alert').alert('close')
    // }, 5000);
  }
}
