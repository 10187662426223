import { Controller } from "stimulus"

export default class extends Controller {
  static values = { src: String }

  connect() {
    const wrapperFrame = this.element.closest('turbo-frame');
    const _this = this;

    setTimeout(function() {
      wrapperFrame.setAttribute('src', null);
      wrapperFrame.setAttribute('src', _this.srcValue);
    }, 2000);
  }
}
