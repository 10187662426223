import { Controller } from "stimulus"
import { get }        from '@rails/request.js'

export default class extends Controller {
  static values = { endpoint: String }

  connect() {
    var config = {
      valueField: 'value',
      labelField: 'text',
      searchField: 'text',
      load: (q, callback) => this.search(q, callback)
    }

    new TomSelect(this.element, config)
  }

  async search(q, callback) {
    const response = await get(this.endpointValue, {
      query: { q: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      console.log(response)
      callback()
    }
  }
}
